import React from "react"
import { Charities, Studiomade, LightsLeft, LightsRight } from "../images"
import LottieContainer from "../components/lottie-container"

class IndexPage extends React.Component {
  constructor(props) {
    super(props)
    this.state = { isStopped: false, isPaused: false }
  }

  render() {
    return (
      <section className="page-content">
        <a className="studiomade-logo" href="https://studiomade.co">
          <img src={Studiomade} alt="studiomade logo" />
        </a>
        <LottieContainer />
        <div className="row centered">
          <img src={LightsLeft} alt="lights" className="lights-left" />
          <img src={LightsRight} alt="lights" className="lights-right" />
          <div className="col col-5 col-text">
            <p>Happy Holidays!</p>
            <p>
              Thank you to all our friends, clients, colleagues and partners for
              your support and input over 2021. We've been proud to be involved
              in some truly exciting projects, from helping repositioning
              exciting new ventures, to launching our own ideas, crafting
              incredible digital experiences and building campaigns that help
              support individual brand growth throughout 2021. It's been a
              challenging year, but one that we've relished and – for Studiomade
              – will take all this year's efforts very positively into what we
              feel will be a great 2022. We couldn't have done it though without
              the team work from everyone within Studiomade, and for the working
              relationships that we are so fortunate to have with all our
              clients.
            </p>
            <p>
              This Christmas, we wanted to show our support to those that need
              it. For our Christmas giving we have donated to two amazing
              charities on your behalf;{" "}
              <a href="https://www.shelter.org.uk/" target="_blank">
                Shelter
              </a>{" "}
              and{" "}
              <a href="https://ineeds.org.uk/" target="_blank">
                International Needs
              </a>
              . Combined, they support people both within the UK and globally
              through food banks, emergency food provision and support to people
              in poverty.
            </p>

            <img src={Charities} alt="Shelter + International Needs" />

            <p>
              {" "}
              From all the team at Studiomade we hope you have a really lovely
              Christmas and we will see you in the New Year!!
            </p>
            <br />
            <br />
            <p className="phil">
              <strong>Phil Reid</strong>
            </p>
            <p className="phil-title"> Founder &amp; Strategic Director </p>
            <br />
            <br />
            <p className="link">
              Visit <a href="https://studiomade.co">Studiomade</a>
            </p>
          </div>
        </div>
      </section>
    )
  }
}

export default IndexPage
