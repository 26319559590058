import React, { Component } from "react"
import Lottie from "react-lottie"
import animationData from "../video/Christmas_2021_V9.json"

class LottieContainer extends Component {
  render() {
    const defaultOptions = {
      loop: true,
      autoplay: true,
      animationData: animationData,
      rendererSettings: {
        preserveAspectRatio: "xMidYMid slice",
      },
    }

    return (
      <div className="lottie-container">
        <Lottie options={defaultOptions} className="lottie" />
      </div>
    )
  }
}

export default LottieContainer
